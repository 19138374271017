import React from 'react';
import {graphql} from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import Img from 'gatsby-image';

class ProjectTemplate extends React.Component {
    render() {
        const post = get(this.props, 'data.contentfulProject');
        const siteTitle = get(this.props, 'data.site.siteMetadata.title');

        return (
            <div className="container mx-auto w-1/3">
                <Helmet title={`${post.title} | ${siteTitle}`} />
                <div>
                    <Img alt={post.title} fluid={post.heroImage.fluid} />
                </div>
                <div className="wrapper">
                    <h1 className="section-headline">{post.title}</h1>
                    <p
                        style={{
                            display: 'block',
                        }}>
                        {post.publishDate}
                    </p>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.body.childMarkdownRemark.html,
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default ProjectTemplate;

export const pageQuery = graphql`
    query ProjectBySlug($slug: String!) {
        contentfulProject(slug: {eq: $slug}) {
            title
            body {
                childMarkdownRemark {
                    html
                }
            }
            heroImage {
                fluid(maxWidth: 800) {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
        }
    }
`;
